import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Account
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api';
    static UNI_URL = 'https://auth.x-force.red/api/sso/init/' + window.location.hostname;
    static EXT_URL = 'https://www.ibm.com/security/services/offensive-security-services';

    sso_url(){
        return Account.UNI_URL;
    }
    photo_url(){
        return `${Account.API_URL}/account/photo`;
    }
    logout(){
        const url = `${Account.API_URL}/account/logout`;
        return axios.get(url).then(response => response.data);
    }
    user(){
        const url = `${Account.API_URL}/account/user`;
        return axios.get(url).then(response => response.data);
    }
    fault(){
        const url = `${Account.API_URL}/sso/fault`;
        return axios.get(url).then(response => response.data);
    }
    unified_auth() {
        window.location = Account.UNI_URL;
    }
    bail() {
        window.location = Account.EXT_URL;
    }
    is_authenticated_user(user) {
        return user  && 'authorised' in user && user.authorised;
    }
}
