import React, { Component } from 'react';
import {
    Header,
    HeaderName,
    HeaderNavigation,
    HeaderMenuItem,
    HeaderGlobalBar,
    HeaderGlobalAction,
    HeaderPanel,
    SkipToContent,
    Switcher,
    SwitcherItem,
    SwitcherDivider, HeaderMenuButton
} from "carbon-components-react/es/components/UIShell";
import IbmSecurity20 from "@carbon/icons-react/lib/ibm-security/20";
import Debug24 from "@carbon/icons-react/lib/debug/24";
import FaceDizzy20 from "@carbon/icons-react/lib/face--dizzy/20";
import FaceDissatisfied20 from "@carbon/icons-react/lib/face--dissatisfied/20";
import FaceNeutral20 from "@carbon/icons-react/lib/face--neutral/20";
import FaceSatisfied20 from "@carbon/icons-react/lib/face--satisfied/20";
import FaceWink20 from "@carbon/icons-react/lib/face--wink/20";
import { Link } from "react-router-dom";
import './_main-header.scss';
import {
    Form,
    FormGroup,
    FormLabel,
    Modal,
    Dropdown,
    TextArea,
    Button,
    Toggle,
    Tooltip,
    TextInput, SideNav, SideNavItems, HeaderSideNavItems, HeaderContainer
} from "carbon-components-react";
import axios from "axios";
import SessionContext from "../SessionContext";
import Account from "../../backend/Account";


const account = new Account();
const XFB_URL = window.location.protocol + '//' + window.location.hostname + '/api/feedback/';


class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.defaultFeedback = {
            priority: 'info', category: 'other', subject: '', message: '', inclusive: false
        };
        this.state = {panelActive: false, modalActive: false, priorities: [
            {id: 'info', text: 'I don\'t really care, I\'m just bored', icon: (<FaceWink20 fill="#f4f4f4"/>)},
            {id: 'low', text: 'I\'ll probably forget about it in a week anyway', icon: (<FaceSatisfied20 fill="#42be65"/>)},
            {id: 'medium', text: 'Kinda important, but finish your coffee first', icon: (<FaceNeutral20 fill="#4589ff"/>)},
            {id: 'high', text: 'ASAP if you could please and thank you', icon: (<FaceDissatisfied20 fill="#f1c21b"/>)},
            {id: 'critical', text: 'HELP ME NOW! No seriously, its urgent!', icon: (<FaceDizzy20 fill="#fa4d56"/>)}
        ], categories: [
            {id: 'bug', text: 'I found a bug! I\'m sure it\'s not a feature'},
            {id: 'feature', text: 'I have a feature request, you\'ll love it!'},
            {id: 'help', text: 'Help! I have no idea what\'s going on here'},
            {id: 'karen', text: 'I want to speak to the manager... Now!'},
            {id: 'other', text: 'Sorry... What was the question again?'},
        ], feedback: this.defaultFeedback};

        this.submitFeedback = this.submitFeedback.bind(this);
    }
    submitFeedback(session) {
        axios.post(XFB_URL, this.state.feedback, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}}).then(e => {
            if(!e.data || typeof e.data !== 'object')
                return session.addToast('error', 'An error has occurred', 'Unable to send feedback', 'Please try again');

            if(e.data.error && e.data.error)
                return session.addToast('error', 'An error has occurred', 'Unable to send feedback', 'msg' in e.data ? e.data.msg : 'Please try again');

            session.addToast('success', 'Feedback Sent!', 'Successfully sent feedback', 'msg' in e.data ? e.data.msg : 'Thank You');
            this.setState({modalActive: false, feedback: this.defaultFeedback});
        }).catch(session.networkFaultHandler);
    }
    render() {

        return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <Header aria-label="Report Writer">
                <SkipToContent />
                <HeaderMenuButton
                    aria-label="Open menu"
                    onClick={onClickSideNavExpand}
                    isActive={isSideNavExpanded}
                />
                <div className="header-xfr-icon">
                    <IbmSecurity20 />
                </div>
                <HeaderName element={Link} to="/" prefix="X-Force Red">
                    Report Writer
                </HeaderName>
                <HeaderNavigation aria-label="Report Writer">
                    <SessionContext.Consumer>
                        {s => account.is_authenticated_user(s.user) ?
                            <>
                                <HeaderMenuItem element={Link} to="/create" >Create Report</HeaderMenuItem>
                                {/*<HeaderMenuItem element={Link} to="/format" >Manage Format</HeaderMenuItem>*/}
                            </> :
                            <HeaderMenuItem element="a" href={account.sso_url()} >Login with w3id</HeaderMenuItem>
                        }
                    </SessionContext.Consumer>
                </HeaderNavigation>
                <HeaderGlobalBar>
                    <SessionContext.Consumer>
                        {s => account.is_authenticated_user(s.user) &&
                            <HeaderGlobalAction aria-label="User Options" className="xfr-profile-pic" onClick={() => this.setState(s => ({panelActive: !s.panelActive}))} key="user">
                                <img src={account.photo_url()} alt={'forename' in s.user ? s.user.forename : 'User'}/>
                            </HeaderGlobalAction>
                        }
                    </SessionContext.Consumer>
                </HeaderGlobalBar>
                <SessionContext.Consumer>
                    {s => account.is_authenticated_user(s.user) &&
                        <HeaderPanel aria-label="Header Panel" expanded={this.state.panelActive} className="xfr-side-panel">
                            <div className="xfr-hp-inner">
                                <div className="xfr-user-info">
                                    <div className="xui-head">Logged in as:</div>
                                    <div className="xui-sub">{s.user.forename} {s.user.surname} ({s.user.nickname})</div>
                                    <div className="xui-sub">{s.user.email}</div>
                                </div>
                                <Switcher aria-label="User" className="xfr-switcher">
                                    <SwitcherDivider />
                                    <SwitcherItem aria-label="Logout" href="/api/account/logout">Logout</SwitcherItem>
                                    <SwitcherDivider />
                                </Switcher>
                            </div>
                            <div className="xfr-side-footer">
                                <div className="xfr-feedback">
                                    <Button
                                        className="xfb-btn" size="small" renderIcon={Debug24} kind="ghost"
                                        onClick={() => this.setState({modalActive: true})}
                                    >Found a bug?</Button>
                                    <Modal danger hasForm shouldSubmitOnEnter size="sm"
                                           id="xfr-modal" className="xfr-bug-modal" label="feedback" aria-label="feedback"
                                           open={this.state.modalActive} onRequestSubmit={() => this.submitFeedback(s)}
                                           onRequestClose={() => this.setState({modalActive: false})}
                                           primaryButtonText="Send feedback"
                                           secondaryButtonText="Nah I'm good actually"
                                           modalAriaLabel="Send feedback or request a feature"
                                           modalHeading="Need something?"
                                           modalLabel="Feedback and feature requests"
                                    >
                                        <div className="xfb-form">
                                            <Form>
                                                <FormGroup legendText="Request Details">
                                                    <Dropdown className="xfb-dropdown"
                                                        id="priority" titleText="Priority"
                                                        label="How important is this, really?" size="xl"
                                                        items={this.state.priorities}
                                                        itemToString={(item) => (item ? item.text : '')}
                                                        onChange={({ selectedItem }) => this.setState(s => ({feedback: {...s.feedback, priority: selectedItem.id}}))}
                                                        itemToElement={(item) => item ? (
                                                            <div className="xfb-dropdown-element">
                                                                <div className="xfb-rde-icon">{item.icon}</div>
                                                                <div className="xfb-rde-text">{item.text}</div>
                                                            </div>
                                                        ) : ('')}
                                                    />
                                                    <br/>
                                                    <Dropdown className="xfb-dropdown"
                                                        id="type" titleText="Type"
                                                        label="What exactly do you want?" size="xl"
                                                        items={this.state.categories}
                                                        itemToString={(item) => (item ? item.text : '')}
                                                        onChange={({ selectedItem }) => this.setState(s => ({feedback: {...s.feedback, category: selectedItem.id}}))}
                                                    />
                                                </FormGroup>
                                                <FormGroup legendText="Feedback">
                                                    <TextInput
                                                        labelText="Subject" id="subject"
                                                        placeholder="An eye-catching headline to describe your desideratum..."
                                                        onChange={e => {
                                                            let v = e.target.value;
                                                            this.setState(s => ({feedback: {...s.feedback, subject: v}}));
                                                        }} />
                                                    <br/>
                                                    <TextArea
                                                        placeholder="Please provide any additional feedback here..."
                                                        name="feedback" id="feedback" labelText="Any more details?"
                                                        helperText="Your current session data will be submitted alongside anything provided here."
                                                        onChange={e => {
                                                            let v = e.target.value;
                                                            this.setState(s => ({feedback: {...s.feedback, message: v}}));
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup legendText="Client Data">
                                                    <FormLabel id="inclusive">
                                                        <Tooltip triggerText="Are you happy to include any uploaded scan files with your request?">
                                                            Data will be permanently deleted once the request has been processed.
                                                        </Tooltip>
                                                    </FormLabel>
                                                    <Toggle
                                                        id="inclusive" toggled={this.state.feedback.inclusive}
                                                        labelA="Don't touch my stuff! It's private!"
                                                        labelB="Take what you need, I'm not fussed."
                                                        onToggle={() => this.setState(s => ({feedback: {...s.feedback, inclusive: !s.feedback.inclusive}}))}
                                                    />
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </HeaderPanel>
                    }
                </SessionContext.Consumer>
                <SideNav
                    aria-label="Side navigation"
                    expanded={isSideNavExpanded}
                    isPersistent={false}>
                    <SideNavItems>
                        <HeaderSideNavItems>
                            <SessionContext.Consumer>
                                {s => account.is_authenticated_user(s.user) ?
                                    <>
                                        <HeaderMenuItem element={Link} to="/create" >Create Report</HeaderMenuItem>
                                        {/*<HeaderMenuItem element={Link} to="/format" >Manage Format</HeaderMenuItem>*/}
                                    </>
                                    :
                                    <HeaderMenuItem element="a" href={account.sso_url()} >Login with w3id</HeaderMenuItem>
                                }
                            </SessionContext.Consumer>
                        </HeaderSideNavItems>
                    </SideNavItems>
                </SideNav>
            </Header>)}
            />
        );
    }
}
export default MainHeader;