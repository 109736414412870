import React, { Component } from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import {
  ProgressIndicator, ProgressStep, UnorderedList, ListItem, Form, FormGroup, Button, FileUploaderDropContainer,
  ToastNotification, InlineNotification, StructuredListWrapper, StructuredListHead, StructuredListBody,
  StructuredListRow, StructuredListCell, InlineLoading, DataTable, Search, TextInput, DatePicker, DatePickerInput,
  Select, SelectItem, Toggle, Tooltip
} from 'carbon-components-react';
import axios from 'axios';
import Undefined24 from "@carbon/icons-react/lib/undefined/24";
import CheckmarkOutline24 from "@carbon/icons-react/lib/checkmark--outline/24";
import WarningAlt24 from "@carbon/icons-react/lib/warning--alt/24";
import Unknown24 from "@carbon/icons-react/lib/unknown/24";
import InProgress24 from "@carbon/icons-react/lib/in-progress/24";
import Help16 from "@carbon/icons-react/lib/help/16";
import Loading from "../../components/Loading/Loading";
import './_create-report.scss';
// TODO: A lot of this needs to be abstracted away, this is simply an MVP


const {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TableHeader,
  TableSelectAll, TableSelectRow, TableToolbar, TableToolbarSearch, TableToolbarContent
} = DataTable;

// const API_URL = window.location.origin;
const API_URL = window.location.protocol + '//' + window.location.hostname + '/api';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


class CreateReport extends Component {
  static TOAST_TIMEOUT = 3500;
  static SEARCH_TIMEOUT = 250;
  static file_status = {
    UNKNOWN: 0,
    PENDING: 1,
    UPLOADING: 2,
    UPLOADED: 3,
    FAILED: 4
  };
  static stage = {
    UPLOAD: 0,
    PARSE: 1,
    PICK: 2,
    ADD: 3,
    GENERATE: 4
  };
  constructor(props) {
    super(props);
    axios.get(`${API_URL}/init/`);  // We call init the minute the page loads to remove all state data from the backend
    this.state = {
      stage: CreateReport.stage.UPLOAD,
      hasScans: false,
      loading: false,
      generating: false,
      compact: false,
      files: {},
      f_sts: {},
      f_err: {},
      c_err: [],
      d_err: [],
      vulns: [],
      slugs: [],
      search: [],
      preview: [],
      risks: {},
      templates: [],
      report_date: new Date().toISOString().substr(0, 10)
    };
    this.searchTimeout = 0;
    this.onStageChange = this.onStageChange.bind(this);
    this.onAddFiles = this.onAddFiles.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onParse = this.onParse.bind(this);
    this.onPickVuln = this.onPickVuln.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onAddVuln = this.onAddVuln.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onUpdateRisk = this.onUpdateRisk.bind(this);
    this.statusIcon = this.statusIcon.bind(this);
    this.formatBytes = this.formatBytes.bind(this);
    axios.get(`${API_URL}/templates/`).then(e => {
      if(!e.data || typeof e.data !== 'object')
        return this.setState(s => ({c_err: [...s.c_err, 'A network error occurred']}));

      this.setState({templates: e.data});

    }).catch(e => {
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        return this.setState(s => ({c_err: [...s.c_err, 'A network error occurred']}));
      return this.setState(s => ({c_err: [...s.c_err, e.response.data['error']]}));
    });
  }
  onStageChange(i) {  // UPLOAD: 0, PARSE: 1, PICK: 2, ADD: 3, GENERATE: 4
    if (i === CreateReport.stage.PARSE) return;  // disabled, do nothing

    if (i === CreateReport.stage.PICK && !this.state.hasScans)
      return this.setState(s => ({ c_err: [...s.c_err, 'Please upload some scan files first']}));

    if (i === CreateReport.stage.PICK && this.state.vulns.length < 1)
      return this.setState(s => ({ c_err: [...s.c_err, 'Please parse the scan files first']}));

    if (i === CreateReport.stage.PICK && this.state.vulns.length < 1)
      return this.setState(s => ({ c_err: [...s.c_err, 'You have no valid vulnerabilities']}));

    if (i === CreateReport.stage.GENERATE) {
      if (this.state.slugs.length < 1)
        return this.setState(s => ({ c_err: [...s.c_err, 'Please select some vulnerabilities first']}));
      return this.onPreview();
    }

    this.setState({loading: false, stage: i});
  }
  onAddFiles(evt, { addedFiles }) {
    if (addedFiles.length < 1) return;
    let _files = {}, _f_sts = {}, _f_keys = [];
    addedFiles.forEach(f => {
      _files[f.name] = f;
      _f_sts[f.name] = CreateReport.file_status.PENDING;
      _f_keys.push(f.name);
    });
    this.setState(s => ({
      files: {...s.files, ..._files},
      f_sts: {...s.f_sts, ..._f_sts},
      f_err: Object.fromEntries(Object.entries(s.f_err).filter(([k, v]) => !_f_keys.includes(k))),
      loading: false
    }));
  }
  onUpload(e) {
    e.preventDefault();
    const url = `${API_URL}/upload/`;
    let formData = new FormData();
    let _f_sts = {}, skip = [CreateReport.file_status.UPLOADED, CreateReport.file_status.UPLOADING];
    Object.keys(this.state.files).forEach(f => {
      if (!(f in this.state.f_sts) || skip.includes(this.state.f_sts[f])) return;
      _f_sts[f] = CreateReport.file_status.UPLOADING;
      formData.append("scanFiles", this.state.files[f], f);
    });
    this.setState(s => ({f_sts: {...s.f_sts, ..._f_sts}}));
    axios.post(url, formData).then(e => {
      if(!e.data || typeof e.data !== 'object')
        return this.setState(s => ({loading: false, c_err: [...s.c_err, 'A network error occurred']}));
      let _f_sts = {}, _f_err = {}, _f_keys = [];
      Object.keys(e.data).forEach(k => {
        _f_sts[k] = e.data[k]['status'];
        if ('error' in e.data[k]) _f_err[k] = e.data[k]['error'];
        else _f_keys.push(k);  // Mark as successful so we can remove any errors for this file
      });
      this.setState(s => ({
        hasScans: s.hasScans || _f_keys.length > 0,  // Allow continue if at least one file is ok
        f_sts: {...s.f_sts, ..._f_sts},  // Update the state, removing any old errors and appending any new ones
        f_err: {...Object.fromEntries(Object.entries(s.f_err).filter(([k, v]) => !_f_keys.includes(k))), ..._f_err},
        loading: false
      }));
    }).catch(e => {
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        return this.setState(s => ({loading: false, c_err: [...s.c_err, 'A network error occurred']}));
      return this.setState(s => ({loading: false, c_err: [...s.c_err, e.response.data['error']]}));
    });
    return false;
  }
  onParse() {
    const url = `${API_URL}/parse/${this.state.compact ? 1 : 0}`;
    this.setState({loading: true, stage: CreateReport.stage.PARSE});

    axios.get(url).then(e => {
      if(!e.data || typeof e.data !== 'object')
        return this.setState(s => ({ c_err: [...s.c_err, 'A network error occurred']}));
      this.setState(s => ({
        vulns: e.data,
        slugs: [...s.slugs, ...e.data.map(r => r.id)],
        loading: false,
        stage: CreateReport.stage.PICK
      }));
    }).catch(e => {
      let neterr = false;
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        neterr = true;
      return this.setState(s => ({
        c_err: [...s.c_err, neterr ? 'A network error occurred' :  e.response.data['error']],
        loading: false, stage: CreateReport.stage.UPLOAD
      }));
    });
  }
  onPickVuln(selectedRows) {
    if (selectedRows.length < 1) return;
    let _slugs = selectedRows.map(r => r.id);
    this.setState(s => ({
      vulns: [...s.vulns.map(v => {v.isSelected = _slugs.includes(v.id); return v;})],
      slugs: [...s.slugs.filter(v => (_slugs.includes(v) || s.search.map(r => r.id).includes(v)))],
      stage: CreateReport.stage.ADD, loading: false
    }));
  }
  doSearch(query) {
    const url = `${API_URL}/search/`;

    axios.get(url, {params: {q: query}}).then(e => {
      if(!e.data || typeof e.data !== 'object') return;  // Update the search box, keeping anything selected
      this.setState(s => {
        let _search = s.search.filter(v => v.isSelected);
        let _slugs = _search.map(r => r.id);
        return {loading: false, search: [..._search, ...e.data.filter(v => !_slugs.includes(v.id))]};
      });
    }).catch(e => {
      let neterr = false;
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        neterr = true;
      return this.setState(s => ({loading: false, c_err: [...s.c_err, neterr ? 'A network error occurred' :  e.response.data['error']]}));
    });
  }
  onSearch(e) {  // TODO: Paginate search results.... this is getting slow
    clearTimeout(this.searchTimeout);
    let query = e.target.value;
    if (query.length < 1) return;
    let _search = this.doSearch;

    this.searchTimeout = setTimeout(function () { _search(query); }, CreateReport.SEARCH_TIMEOUT);
  }
  onAddVuln(selectedRows) {
    if (selectedRows.length < 1) return this.onPreview();
    let _slugs = selectedRows.map(r => r.id);
    this.setState(s => ({loading: false, search: [...s.search.map(v => {
        v.isSelected = _slugs.includes(v.id);
        return v;
    })], slugs: [...s.slugs.filter(v => s.vulns.map(r => r.id).includes(v)), ..._slugs]}), this.onPreview);
  }
  onPreview() {
    if (this.state.slugs.length < 1)
      return this.setState(s => ({loading: false, c_err: [...s.c_err, 'You must add at least one finding to the report']}));
    this.setState({loading: true});
    //this.setState({loading: true, stage: CreateReport.stage.GENERATE});

    const url = `${API_URL}/preview/`;
    let formData = new FormData();

    this.state.slugs.forEach(s => formData.append('slugs', s));

    axios.post(url, formData).then(e => {
      if(!e.data || typeof e.data !== 'object')
        return this.setState(s => ({ c_err: [...s.c_err, 'A network error occurred']}));
      this.setState({
        preview: e.data, loading: false, stage: CreateReport.stage.GENERATE,
        risks: Object.fromEntries(e.data.map(v => [v.id, v.risk]))
      });
    }).catch(e => {
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        return this.setState(s => ({loading: false, c_err: [...s.c_err, 'A network error occurred']}));
      return this.setState(s => ({loading: false, c_err: [...s.c_err, e.response.data['error']]}));
    });
  }
  onDownload(e) {
    e.preventDefault();

    const url = `${API_URL}/download/`;
    let formData = Object.fromEntries(new FormData(e.target));

    // Fix bug with date resetting after report download
    this.setState({loading: true, generating: true, report_date: formData.date});

    // this.state.slugs.forEach(s => formData.append('slugs', s));
    formData.slugs = this.state.risks;
    let _self = this;

    axios.post(url, formData, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}}).then(e => {
      if(!e.data || typeof e.data !== 'object' || !Object.keys(e.data).includes('blob'))
        return this.setState(s => ({ c_err: [...s.c_err, 'A network error occurred']}));

      let report = new Blob(this.generateByteArrays(e.data['blob']), {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(report); // IE

      let data = window.URL.createObjectURL(report);
      let link = document.createElement('a');
      link.href = data;
      link.download = e.data['name'];

      setTimeout(function(){
        link.click();
        window.URL.revokeObjectURL(data);
        _self.setState({loading: false, generating: false});
      }, 100);

    }).catch(e => {
      if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
        return this.setState(s => ({loading: false, generating: false, c_err: [...s.c_err, 'A network error occurred']}));
      return this.setState(s => ({loading: false, generating: false, c_err: [...s.c_err, e.response.data['error']]}));
    });
    return false;
  }
  onUpdateRisk(slug, value) {
    this.setState(s => ({risks: {...s.risks, ...Object.fromEntries([[slug, value]])}}));
  }
  generateByteArrays(b64Data, sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return byteArrays;
  }
  statusIcon(k) {
    switch(this.state.f_sts[k]) {
      case CreateReport.file_status.PENDING:
        return <Undefined24/>;
      case CreateReport.file_status.UPLOADING:
        return <InProgress24/>;
      case CreateReport.file_status.UPLOADED:
        return <CheckmarkOutline24/>;
      case CreateReport.file_status.FAILED:
        return <WarningAlt24/>;
      case CreateReport.file_status.UNKNOWN:
      default:
        return <Unknown24/>;
    }
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  render() {
    let file_count = Object.keys(this.state.files).length;
    return (
      <Content>
        {this.state.generating && <Loading hasLogo={true} modal={true} message="Generating report..." />}
        <div className="bx--grid bx--grid--full-width create-report-page">
          <div className="bx--row create-report-page__r1">
            <div className="bx--col bx--no-gutter">
              <ProgressIndicator currentIndex={this.state.stage} onChange={this.onStageChange}>
                <ProgressStep // UPLOAD: 0, PARSE: 1, PICK: 2, ADD: 3, GENERATE: 4
                    key={CreateReport.stage.UPLOAD}
                    invalid={file_count < 1}
                    label="Upload Scan Files"
                    description="Step 1: Upload scan files"
                />
                <ProgressStep
                    key={CreateReport.stage.PARSE}
                    invalid={!this.state.hasScans}
                    disabled
                    label="Parse Scans"
                    description="Step 2: Parse the uploaded files"
                />
                <ProgressStep
                    key={CreateReport.stage.PICK}
                    invalid={this.state.vulns.length < 1}
                    label="Pick Findings"
                    description="Step 3: Pick the vulnerabilities to be included in the report"
                />
                <ProgressStep
                    key={CreateReport.stage.ADD}
                    label="Add Findings"
                    description="Step 4: Pick additional findings to add to the report"
                    secondaryLabel="Optional"
                />
                <ProgressStep
                    key={CreateReport.stage.GENERATE}
                    invalid={this.state.slugs.length < 1}
                    label="Generate Report"
                    description="Step 5: Add additional information and generate the report"
                />
              </ProgressIndicator>
            </div>
          </div>
          <div className="bx--row create-report-page__r2">
            <div className="bx--col bx--no-gutter">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">

                {this.state.stage <= CreateReport.stage.PARSE && <div className="bx--row create-report-page__tab-content">
                  <div className="bx--col-md-4 bx--col-lg-6">
                    <h2 className="create-report-page__subheading">
                      Upload Scan Files
                    </h2>
                    <p className="create-report-page__p">
                      Please upload your data files below, supported formats are:
                    </p>
                    <UnorderedList className="create-report-page__ul">
                      <ListItem>Tenable Nessus XML [.nessus]</ListItem>
                      {/*<ListItem>Nmap XML [.xml]</ListItem>
                      <ListItem>IBM AppScan XML [.xml]</ListItem>*/}
                    </UnorderedList>
                    <small>
                      <strong>Please Note: </strong>
                      The filename is used as an identifier so they must be unique when uploading multiple files.
                    </small>
                    <br/><br/>
                    <Form onSubmit={this.onUpload} method="post" encType="multipart/form-data">
                      <FormGroup legendText="Upload Scan Files">
                        <FileUploaderDropContainer
                            accept={[
                              'text/xml',
                              'text/plain',
                                '.nessus'
                            ]}
                            labelText="Drag and drop files here or click to upload"
                            multiple
                            name="scan-files"
                            onAddFiles={this.onAddFiles}
                            role=""
                            size="default"
                            tabIndex={0}
                        />
                        {Object.keys(this.state.f_err).map((e, i) =>
                            <InlineNotification key={i} kind="error" iconDescription="Dismiss" subtitle={this.state.f_err[e]} title="Upload Error" />
                        )}
                      </FormGroup>
                      <Button
                          disabled={file_count < 1 || this.state.loading}
                          kind={this.state.hasScans ? "secondary" : "primary"}
                          tabIndex={1}
                          type="submit"
                      >
                        Upload
                      </Button>
                    </Form>
                  </div>
                  <div className="bx--col-md-8 bx--col-lg-6 sf-wrap">
                    <h3 className="create-report-page__subheading">
                      Scan Files
                    </h3>
                    {file_count < 1 ? <p className="scan-files">No files have been selected.</p> :
                    <StructuredListWrapper ariaLabel="Uploaded Files" className="scan-files">
                      <StructuredListHead>
                        <StructuredListRow head>
                          <StructuredListCell head>Name</StructuredListCell>
                          <StructuredListCell head>Size</StructuredListCell>
                          <StructuredListCell head>Type</StructuredListCell>
                          <StructuredListCell head>Last Modified</StructuredListCell>
                          <StructuredListCell head>Status</StructuredListCell>
                        </StructuredListRow>
                      </StructuredListHead>
                      <StructuredListBody>
                        {Object.keys(this.state.files).map((k, i) =>
                            <StructuredListRow key={i} >
                              <StructuredListCell>{k}</StructuredListCell>
                              <StructuredListCell>{this.formatBytes(this.state.files[k].size)}</StructuredListCell>
                              <StructuredListCell>{this.state.files[k].type}</StructuredListCell>
                              <StructuredListCell>{new Date(this.state.files[k].lastModified).toLocaleString()}</StructuredListCell>
                              <StructuredListCell>{this.statusIcon(k)}</StructuredListCell>
                            </StructuredListRow>
                        )}
                      </StructuredListBody>
                    </StructuredListWrapper>}
                    <div className="sf-base">
					{/*<Toggle defaultToggled={this.state.compact} className="compact-tog" id="compact-toggle" labelText={
                        <Tooltip showIcon align="center" direction="top" triggerText="Compact Reporting" renderIcon={Help16}>
                          <p>
                            When a report contains multiple copies of the same finding, such as outdated software,
                            merge these into single findings for each individual product. The highest risk rating
                            found for each product will be used as the base risk for that product's merged finding.
                          </p>
                        </Tooltip>
                      } onToggle={v => this.setState({compact: v})}/>*/}

                      {this.state.loading ? <InlineLoading className="continue" description="Parsing..." /> : <Button
                          disabled={!this.state.hasScans}
                          kind="primary"
                          type="button"
                          className="continue"
                          tabIndex={2}
                          onClick={this.onParse}
                      >
                        Continue
                      </Button>}
                    </div>
                  </div>
                </div>}

                {this.state.stage === CreateReport.stage.PICK && <div className="bx--row create-report-page__tab-content">
                  <div className="bx--col vuln-picker">
                    <h3 className="create-report-page__subheading">Pick Vulnerabilities</h3>
                    {this.state.vulns.length < 1 ? <p>No vulnerabilities were found.</p> :
                      <DataTable
                          headers={[
                            {header: 'Name', key: 'name'},
                            {header: 'Synopsis', key: 'synopsis'},
                            {header: 'Solution', key: 'solution'},
                            {header: 'Risk', key: 'risk'},
                            {header: 'Count', key: 'count'}
                          ]}
                          locale="en"
                          rows={this.state.vulns}
                          size="short"
                          stickyHeader={true}
                          isSortable={true}
                          render={({ rows, headers, getHeaderProps, getSelectionProps, onInputChange, selectedRows }) => (
                              <TableContainer>
                                <TableToolbar>
                                  <TableToolbarContent>
                                    <TableToolbarSearch onChange={onInputChange} />
                                    <Button
                                        disabled={selectedRows.length < 1}
                                        kind="primary"
                                        type="button"
                                        tabIndex={5}
                                        onClick={() => this.onPickVuln(selectedRows)}
                                    >
                                      Continue
                                    </Button>
                                  </TableToolbarContent>
                                </TableToolbar>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableSelectAll {...getSelectionProps()} />
                                      {headers.map(header => (
                                          <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                          </TableHeader>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map(row => (
                                        <TableRow key={row.id}>
                                          <TableSelectRow {...getSelectionProps({ row })} />
                                          {row.cells.map(cell => (
                                              <TableCell key={cell.id}>{cell.value}</TableCell>
                                          ))}
                                        </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                          )}
                      />}
                  </div>
                </div>}

                {this.state.stage === CreateReport.stage.ADD && <div className="bx--row create-report-page__tab-content">
                  <div className="bx--col vuln-picker">
                    <h3 className="create-report-page__subheading">Add Vulnerabilities</h3>
                    <small>Search results have a hard limit of 150, please be specific in your search query</small>
                    <DataTable
                        headers={[
                          {header: 'Name', key: 'name'},
                          {header: 'Synopsis', key: 'synopsis'},
                          {header: 'Solution', key: 'solution'},
                          {header: 'Risk', key: 'risk'}
                        ]}
                        locale="en"
                        rows={this.state.search}
                        size="short"
                        stickyHeader={true}
                        isSortable={true}
                        render={({ rows, headers, getHeaderProps, getSelectionProps, selectedRows }) => (
                            <TableContainer>
                              <TableToolbar>
                                <TableToolbarContent>
                                  <Search
                                      className="add-vuln-search"
                                      closeButtonLabelText="Clear search input"
                                      defaultValue=""
                                      id="search-vulns"
                                      labelText="Search for a finding"
                                      placeHolderText="Search for a finding"
                                      name=""
                                      onChange={this.onSearch}
                                      size="xl"
                                      type="text"
                                  />
                                  {this.state.loading ? <InlineLoading className="continue" description="Loading..." /> : <Button
                                      disabled={selectedRows.length < 1 && this.state.vulns.filter(v => v.isSelected).length < 1}
                                      kind="primary"
                                      type="button"
                                      tabIndex={5}
                                      onClick={() => this.onAddVuln(selectedRows)}
                                  >
                                    Continue
                                  </Button>}
                                </TableToolbarContent>
                              </TableToolbar>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableSelectAll {...getSelectionProps()} />
                                    {headers.map(header => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                          {header.header}
                                        </TableHeader>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows.map(row => (
                                      <TableRow key={row.id}>
                                        <TableSelectRow {...getSelectionProps({ row })} />
                                        {row.cells.map(cell => (
                                            <TableCell key={cell.id}>{cell.value}</TableCell>
                                        ))}
                                      </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                        )}
                    />
                  </div>
                </div>}

                {this.state.stage === CreateReport.stage.GENERATE && <div className="bx--row create-report-page__tab-content">
                  <div className="bx--col-md-4 bx--col-lg-6">
                    <h2 className="create-report-page__subheading">Generate Report</h2>
                    <p className="create-report-page__p">Findings List</p>
                    {this.state.preview.length < 1 ? <p>No findings have been selected.</p> :
                        <StructuredListWrapper ariaLabel="Selected Findings" className="preview-list">
                          <StructuredListHead>
                            <StructuredListRow head>
                              <StructuredListCell head>Name</StructuredListCell>
                              <StructuredListCell head noWrap={true}>Risk</StructuredListCell>
                              <StructuredListCell head noWrap={true}>Count</StructuredListCell>
                            </StructuredListRow>
                          </StructuredListHead>
                          <StructuredListBody>
                            {this.state.preview.map((v, i) =>
                                <StructuredListRow key={i} >
                                  <StructuredListCell>{v.name}</StructuredListCell>
                                  <StructuredListCell noWrap={true}>
                                    <Select
                                        defaultValue={this.state.risks[v.id]}
                                        light={false} required size="sm"
                                        labelText={"Original risk: " + v.risk}
                                        name={"risk-" + v.id} id={"risk-" + v.id}
                                        onChange={e => this.onUpdateRisk(v.id, e.target.value)}
                                    >
                                      <SelectItem text="Informational" value="Informational" />
                                      <SelectItem text="Low" value="Low" />
                                      <SelectItem text="Medium" value="Medium" />
                                      <SelectItem text="High" value="High" />
                                      <SelectItem text="Critical" value="Critical" />
                                    </Select>
                                  </StructuredListCell>
                                  <StructuredListCell noWrap={true}>{v.count > 0 ? v.count : "New"}</StructuredListCell>
                                </StructuredListRow>
                            )}
                          </StructuredListBody>
                        </StructuredListWrapper>}
                  </div>
                  <div className="bx--col-md-8 bx--col-lg-6">
                    <Form onSubmit={this.onDownload} method="post" encType="multipart/form-data">
                      <FormGroup legendText="Report Configuration">
                        <Select
                            defaultValue="placeholder-item"
                            helperText=""
                            invalidText=""
                            labelText="Template"
                            light={false}
                            required
                            name="type" id="type"
                        >
                          <SelectItem disabled text="Choose an option" value="placeholder-item" />
                          {this.state.templates.map((v, i) =>
                              <SelectItem key={i} text={v.name} value={v.id} />
                          )}
                        </Select>
                        <TextInput required id="target" name="target" labelText="Target" placeholder="Assessment Target" />
                        <TextInput required id="client" name="client" labelText="Client" placeholder="Client Name" />
                        <DatePicker required datePickerType="single" dateFormat="Y-m-d" light={false}>
                          <DatePickerInput
                              iconDescription="Pick a date"
                              invalidText="A valid value is required"
                              labelText="Report Date"
                              placeholder="Report Date"
                              value={this.state.report_date}
                              name="date" id="date"
                              pattern="\d{4}-\d{1,2}-\d{1,2}"/>
                        </DatePicker>
                        {Object.keys(this.state.d_err).map((e, i) =>
                            <InlineNotification key={i} kind="error" iconDescription="Dismiss" subtitle={this.state.d_err[e]} title="Generation Error" />
                        )}
                      </FormGroup>
                      <div className="download-note">
                        <strong>Please Note: </strong>
                        <i>When opening the document please choose "yes" when asked to update fields.</i>
                      </div>
                      <Button kind="primary" tabIndex={7} type="submit" id="dl-submit">Download Report</Button>
                    </Form>
                  </div>
                </div>}

              </div>
            </div>
          </div>
          <div className="toasty">
            {this.state.c_err.map((e, i) =>
                <ToastNotification key={i} kind="error" iconDescription="Dismiss" subtitle={e} title="Error" caption="Please try again" timeout={CreateReport.TOAST_TIMEOUT} />
            )}
          </div>
        </div>
      </Content>
    );
  }
}

export default CreateReport;