import  React, { Component } from  'react';
import './_loading.scss';

class Loading extends Component {
    static defaultProps = {
        message: 'Loading...',
        hasLogo: false,
        modal: false
    };
    render() {
        const { message, hasLogo, modal } = this.props;
        let wrapperClass = "loading-spinner d-flex justify-content-center mt-5";
        let containerClass = "container loading-container";

        if (hasLogo) wrapperClass += " loading-logo";
        if (modal) containerClass += " loading-modal";

        return (<div className={containerClass}>
            <div className={wrapperClass}>
                <div className="loading-inner">
                    {hasLogo ? <>
                        <div className="xfr-logo-box like-a-record-baby-right-round-round-round">
                            <div/><div/><div/>
                            <div/><div/><div/>
                            <div/><div/><div/>
                        </div>{message && (<h1>{message}</h1>)}
                        </>
                    :
                        <>
                            <div className="lds-ripple"><div/><div/></div>
                            {message && (<h1>{message}</h1>)}
                        </>
                    }
                </div>
            </div>
        </div>);
    }
}
export default Loading;
