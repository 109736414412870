import React, { Component } from 'react';
import { ToastNotification, Grid, Row, Column } from 'carbon-components-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import './_manage-format.scss';
import { DefaultNessusXML } from './default';

// const API_URL = window.location.origin;
// const API_URL = window.location.protocol + '//' + window.location.hostname + ':8080/api';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;



class ManageFormat extends Component {
  static TOAST_TIMEOUT = 3500;
  constructor(props) {
    super(props);
    this.state = {err: []};
  }

  render() {
    return (
        <main className="bx--content manage-format-page">
            <Grid fullWidth className="xfr-fmt-grid">
                <Row as="section" narrow="true">
                    <Column lg={6}>
                        <SyntaxHighlighter
                            language="markup" style={materialDark}
                            showLineNumbers={true} showInlineLineNumbers={true}
                            wrapLines={true} wrapLongLines={true}
                        >
                            {DefaultNessusXML}
                        </SyntaxHighlighter>
                    </Column>
                    <Column lg={6}>

                    </Column>
                </Row>
            </Grid>
            <div className="toasty">
            {this.state.err.map((e, i) =>
                <ToastNotification key={i} kind="error" iconDescription="Dismiss" subtitle={e} title="Error" caption="Please try again" timeout={ManageFormat.TOAST_TIMEOUT} />
            )}
            </div>
        </main>
    );
  }
}

export default ManageFormat;