import React, { Component } from 'react';
import { ToastNotification } from 'carbon-components-react';
import axios from 'axios';
import './_upload-report.scss';

// const API_URL = window.location.origin;
// const API_URL = window.location.protocol + '//' + window.location.hostname + ':8080/api';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;



class UploadReport extends Component {
  static TOAST_TIMEOUT = 3500;
  constructor(props) {
    super(props);
    this.state = {err: []};
  }

  render() {
    return (
        <main className="bx--content edit-report-page">

          <div className="toasty">
            {this.state.err.map((e, i) =>
                <ToastNotification key={i} kind="error" iconDescription="Dismiss" subtitle={e} title="Error" caption="Please try again" timeout={UploadReport.TOAST_TIMEOUT} />
            )}
          </div>
        </main>
    );
  }
}

export default UploadReport;