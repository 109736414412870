import React from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import './_landing-page.scss';

const LandingPage = () => {
  return (
  <Content>
    <div>
      <h4>Welcome to the X-Force Red Report Writer</h4>
      <br/>
      <p>Please choose a menu item above to get started.</p>
      <br/><br/><br/>
      <small>
        <strong>Please Note:</strong> this is an alpha release so please be vigilant when verifying generated reports.
      </small>
    </div>
    <footer className="xfr-footer">
      <div className="xfr-author">
      <span>
          Built with <span className="xf-icon">♥</span> by
          <a className="xf-repo" href="https://github.ibm.com/Ryan-Ward1/rw2" target="_blank" rel="noopener noreferrer">Zo0x</a>.
      </span>
      </div>
    </footer>
  </Content>
  );
};

export default LandingPage;
