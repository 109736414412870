import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.scss';
import { ToastNotification } from 'carbon-components-react';
import Account from "./backend/Account";
import MainHeader from "./components/MainHeader";
import LandingPage from "./content/LandingPage";
import CreateReportPage from "./content/CreateReportPage";
import UploadReportPage from "./content/UploadReportPage";
import ManageFormatPage from "./content/ManageFormatPage";
import SessionContext from "./components/SessionContext";
import ManageFormat from "./content/ManageFormatPage";

const account = new Account();

class App extends Component {
    constructor(props) {
        super(props);
        this.addToast = this.addToast.bind(this);
        this.addError = this.addError.bind(this);
        this.addInfo = this.addInfo.bind(this);
        this.addWarning = this.addWarning.bind(this);
        this.addSuccess = this.addSuccess.bind(this);
        this.networkFaultHandler = this.networkFaultHandler.bind(this);

        this.state = {user: {}, toasts: [], addToast: this.addToast, networkFaultHandler: this.networkFaultHandler};
    }
    addToast(kind, title, subtitle, caption, timeout=3500) {
        // kind: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'
        return this.setState(s => ({toasts: [...s.toasts, {
                kind: kind, title: title, subtitle: subtitle, caption: caption, timeout: timeout
        }]}));
    }
    addError(message, caption='Please try again', title='Error') {
        return this.addToast('error', title, message, caption)
    }
    addInfo(message, caption='Information', title='Information') {
        return this.addToast('info', title, message, caption)
    }
    addWarning(message, caption='Warning', title='Warning') {
        return this.addToast('warning', title, message, caption)
    }
    addSuccess(message, caption='Operation Successful', title='Success') {
        return this.addToast('success', title, message, caption)
    }
    networkFaultHandler(e) {
        console.log(e);
        if(!e.data)
            if (!e.response || !e.response.data) return this.addError('A network error occurred');
            e.data = e.response.data;

        if(typeof e.data !== 'object' || !('error' in e.data)) return this.addError('A network error occurred');
        return this.addError(e.data['error']);
    }
    componentDidMount() {
        account.fault().then(d => {
            if (d && d.error && d.error.reason) this.addError(d.error.reason);
        }).catch(this.networkFaultHandler);

        account.user().then(d => {
            if (d) this.setState({user: d});
        }).catch(this.networkFaultHandler);
    }
    render() {
        return (
            <div className="App">
                <SessionContext.Provider value={this.state}>
                    <MainHeader/>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route path="/create" component={CreateReportPage} />
                        <Route path="/upload" component={UploadReportPage} />
                        <Route path="/format" component={ManageFormatPage} />
                    </Switch>
                    <div className="xfr-toaster">
                        <SessionContext.Consumer>
                        {e => e.toasts.map((t, i) =>
                            <ToastNotification key={i} kind={t.kind} iconDescription="Dismiss" subtitle={t.subtitle} title={t.title} caption={t.caption} timeout={t.timeout} />
                        )}
                        </SessionContext.Consumer>
                    </div>
                </SessionContext.Provider>
            </div>
        );
    }
}

export default App;
