
export const DefaultNessusXML = `<?xml version="1.0"?>
<NessusClientData_v2>
  <Report xmlns:cm="http://www.nessus.org/cm" name="Remote">
    <ReportHost name="preprod.boardvantage.net">
      <HostProperties>
        <tag name="HOST_END">Tue Jun 25 10:39:42 2013</tag>
        <tag name="patch-summary-total-cves">0</tag>
        <tag name="system-type">firewall</tag>
        <tag name="operating-system">CISCO PIX 7.0</tag>
        <tag name="host-ip">12.233.108.201</tag>
        <tag name="host-fqdn">preprod.boardvantage.net</tag>
        <tag name="HOST_START">Tue Jun 25 10:32:42 2013</tag>
      </HostProperties>
      <ReportItem port="0" svc_name="general" protocol="tcp" severity="0" pluginID="19506" pluginName="Nessus Scan Information" pluginFamily="Settings">
        <description>This script displays, for each tested host, information about the scan itself :

\t\t\t\t\t- The version of the plugin set
\t\t\t\t\t- The type of plugin feed (HomeFeed or ProfessionalFeed)
\t\t\t\t\t- The version of the Nessus Engine
\t\t\t\t\t- The port scanner(s) used
\t\t\t\t\t- The port range scanned
\t\t\t\t\t- Whether credentialed or third-party patch management    checks are possible
\t\t\t\t\t- The date of the scan
\t\t\t\t\t- The duration of the scan
\t\t\t\t\t- The number of hosts scanned in parallel
\t\t\t\t\t- The number of checks done in parallel</description>
        <fname>scan_info.nasl</fname>
        <plugin_modification_date>2013/05/31</plugin_modification_date>
        <plugin_name>Nessus Scan Information</plugin_name>
        <plugin_publication_date>2005/08/26</plugin_publication_date>
        <plugin_type>summary</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 1.59 $</script_version>
        <solution>n/a</solution>
        <synopsis>Information about the Nessus scan.</synopsis>
        <plugin_output>Information about this scan : 

\t\t\t\t\tNessus version : 5.2.1
\t\t\t\t\tPlugin feed version : 201306251015
\t\t\t\t\tType of plugin feed : HomeFeed (Non-commercial use only)
\t\t\t\t\tScanner IP : 172.16.138.167
\t\t\t\t\tPort scanner(s) : nessus_syn_scanner 
\t\t\t\t\tPort range : default
\t\t\t\t\tThorough tests : no
\t\t\t\t\tExperimental tests : no
\t\t\t\t\tParanoia level : 1
\t\t\t\t\tReport Verbosity : 1
\t\t\t\t\tSafe checks : yes
\t\t\t\t\tOptimize the test : yes
\t\t\t\t\tCredentialed checks : no
\t\t\t\t\tPatch management checks : None
\t\t\t\t\tCGI scanning : enabled
\t\t\t\t\tWeb application tests : enabled
\t\t\t\t\tWeb app tests -  Test mode : some_pairs
\t\t\t\t\tWeb app tests -  Try all HTTP methods : no
\t\t\t\t\tWeb app tests -  Maximum run time : 60 minutes.
\t\t\t\t\tWeb app tests -  Stop at first flaw : CGI
\t\t\t\t\tMax hosts : 80
\t\t\t\t\tMax checks : 5
\t\t\t\t\tRecv timeout : 5
\t\t\t\t\tBackports : None
\t\t\t\t\tAllow post-scan editing: Yes
\t\t\t\t\tScan Start Date : 2013/6/25 10:32
\t\t\t\t\tScan duration : 416 sec
\t\t\t\t</plugin_output>
      </ReportItem>
      <ReportItem port="0" svc_name="general" protocol="tcp" severity="0" pluginID="54615" pluginName="Device Type" pluginFamily="General">
        <description>Based on the remote operating system, it is possible to determine what the remote system type is (eg: a printer, router, general-purpose computer, etc).</description>
        <fname>device_type.nasl</fname>
        <plugin_modification_date>2011/05/23</plugin_modification_date>
        <plugin_name>Device Type</plugin_name>
        <plugin_publication_date>2011/05/23</plugin_publication_date>
        <plugin_type>combined</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 1.1 $</script_version>
        <solution>n/a</solution>
        <synopsis>It is possible to guess the remote device type.</synopsis>
        <plugin_output>Remote device type : firewall
\t\t\t\t\tConfidence level : 70
\t\t\t\t</plugin_output>
      </ReportItem>
      <ReportItem port="0" svc_name="general" protocol="tcp" severity="0" pluginID="45590" pluginName="Common Platform Enumeration (CPE)" pluginFamily="General">
        <description>By using information obtained from a Nessus scan, this plugin reports CPE (Common Platform Enumeration) matches for various hardware and software products found on a host. 

\t\t\t\t\tNote that if an official CPE is not available for the product, this plugin computes the best possible CPE based on the information available from the scan.</description>
        <fname>cpe.nbin</fname>
        <plugin_modification_date>2013/05/13</plugin_modification_date>
        <plugin_name>Common Platform Enumeration (CPE)</plugin_name>
        <plugin_publication_date>2010/04/21</plugin_publication_date>
        <plugin_type>local</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 1.19 $</script_version>
        <see_also>http://cpe.mitre.org/</see_also>
        <solution>n/a</solution>
        <synopsis>It is possible to enumerate CPE names that matched on the remote system.</synopsis>
        <plugin_output>
\t\t\t\t\tThe remote operating system matched the following CPE : 

\t\t\t\t\tcpe:/o:cisco:pix_firewall:7.0
\t\t\t\t</plugin_output>
      </ReportItem>
      <ReportItem port="0" svc_name="general" protocol="tcp" severity="0" pluginID="11936" pluginName="OS Identification" pluginFamily="General">
        <description>Using a combination of remote probes (TCP/IP, SMB, HTTP, NTP, SNMP, etc...), it is possible to guess the name of the remote operating system in use. It is also sometimes possible to guess the version of the operating system.</description>
        <fname>os_fingerprint.nasl</fname>
        <plugin_modification_date>2013/04/01</plugin_modification_date>
        <plugin_name>OS Identification</plugin_name>
        <plugin_publication_date>2003/12/09</plugin_publication_date>
        <plugin_type>combined</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 2.34 $</script_version>
        <solution>n/a</solution>
        <synopsis>It is possible to guess the remote operating system.</synopsis>
        <plugin_output>
\t\t\t\t\tRemote operating system : CISCO PIX 7.0
\t\t\t\t\tConfidence Level : 70
\t\t\t\t\tMethod : SinFP


\t\t\t\t\tThe remote host is running CISCO PIX 7.0</plugin_output>
      </ReportItem>
      <ReportItem port="0" svc_name="general" protocol="tcp" severity="0" pluginID="12053" pluginName="Host Fully Qualified Domain Name (FQDN) Resolution" pluginFamily="General">
        <description>Nessus was able to resolve the FQDN of the remote host.</description>
        <fname>fqdn.nasl</fname>
        <plugin_modification_date>2012/09/28</plugin_modification_date>
        <plugin_name>Host Fully Qualified Domain Name (FQDN) Resolution</plugin_name>
        <plugin_publication_date>2004/02/11</plugin_publication_date>
        <plugin_type>remote</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 1.12 $</script_version>
        <solution>n/a</solution>
        <synopsis>It was possible to resolve the name of the remote host.</synopsis>
        <plugin_output>
\t\t\t\t\t12.233.108.201 resolves as preprod.boardvantage.net.
\t\t\t\t</plugin_output>
      </ReportItem>
      <ReportItem port="443" svc_name="https?" protocol="tcp" severity="0" pluginID="11219" pluginName="Nessus SYN scanner" pluginFamily="Port scanners">
        <description>This plugin is a SYN 'half-open' port scanner.
\t\t\t\t\tIt shall be reasonably quick even against a firewalled target.

\t\t\t\t\tNote that SYN scanners are less intrusive than TCP (full connect) scanners against broken services, but they might kill lame misconfigured firewalls. They might also leave unclosed connections on the remote target, if the network is loaded.</description>
        <fname>nessus_syn_scanner.nbin</fname>
        <plugin_modification_date>2011/04/05</plugin_modification_date>
        <plugin_name>Nessus SYN scanner</plugin_name>
        <plugin_type>remote</plugin_type>
        <risk_factor>None</risk_factor>
        <script_version>$Revision: 1.14 $</script_version>
        <solution>Protect your target with an IP filter.</solution>
        <synopsis>It is possible to determine which TCP ports are open.</synopsis>
        <plugin_output>Port 443/tcp was found to be open</plugin_output>
      </ReportItem>
    </ReportHost>
  </Report>
</NessusClientData_v2>
`;